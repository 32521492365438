<template>
  <div class="text-center margin-top-sm">
    <div class="image">
      <div class="text-lg text-bold" v-if="!currentUrl">
        <i class="el-icon-loading"></i>
        正在生成...
      </div>
      <img style="width: 100%; height: auto" :src="currentUrl" />
    </div>
    <div class="margin-top-sm">
      <div style="display: inline-block">
        <div class="flex">
          <div>
            <el-button
              v-clipboard:copy="localUrl + '?sCode=' + userShareCode"
              v-clipboard:success="() => $notify({ title: '复制成功' })"
              >复制链接 <i class="el-icon-document-copy"
            /></el-button>
          </div>
          <div class="margin-lr-xs">
            <el-upload
              :on-change="handleChange"
              action=""
              :show-file-list="false"
              accept="image/gif,image/jpeg,image/jpg,image/png,image/svg,image/webp,image/bmp"
            >
              <el-button
                >上传背景
                <i class="el-icon-refresh" />
              </el-button>
            </el-upload>
          </div>
          <div>
            <a :href="currentUrl" target="blank" download="分享海报">
              <el-button type="primary"
                >下载海报
                <i class="el-icon-download" />
              </el-button>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div v-if="painting.views.length && painting.background">
      <vue-canvas-poster
        :widthPixels="1920"
        :painting="painting"
        @success="success"
        @fail="fail"
      />
    </div>
  </div>
</template>
<script>
import { getShareCode } from "@/http/interface";
import { VueCanvasPoster } from "vue-canvas-poster";

export default {
  components: {
    VueCanvasPoster,
  },
  data() {
    return {
      currentUrl: "",
      painting: {
        width: "375px",
        height: "667px",
        background: require("/static/share-bg.jpg"),
        views: [],
      },
      userShareCode: "",
      localUrl: window.location.origin,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  async created() {
    const { data } = await getShareCode();
    this.userShareCode = data;
    this.painting.views = [
      {
        type: "qrcode",
        content: this.localUrl + "?sCode=" + data,
        css: {
          bottom: "40px",
          right: 375 / 2 - 40 + "px",
          color: "#000",
          background: "#fff",
          width: "80px",
          height: "80px",
          borderWidth: "5px",
          borderColor: "#fff",
          borderRadius: "1px",
        },
      },
    ];
  },

  methods: {
    handleChange(file) {
      console.log(file, "上传文件", file.raw);
      this.painting.background = undefined;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.painting.background = e.target.result;
      };
      reader.readAsDataURL(file.raw);
    },
    success(src) {
      this.currentUrl = src;
    },
    fail(err) {
      console.log("fail", err);
      this.$message.error("图片加载失败，请重试！");
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  display: inline-block;
  width: 375px;
  height: 667px;
  padding: 10px;
  font-size: 0;
}
</style>